@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url("./fonts/Inter.ttf") format("truetype");
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Geist";
  src: url(./fonts/GeistVF.woff2) format("woff2");
}

@layer base {
  :root {
    --font-code: "SFMono Regular", Consolas, "Liberation Mono", Menlo, Courier,
      monospace;


    --page-width: 66.5rem;

    --color-red: #e04f44;
    --color-purple: #5F6AD3;
    --color-blue: #4c68d6;
    --color-green: #259646;
    --color-yellow: #F2BE00;
    --color-orange: #EAB586;
    --color-pink: #c75485;
    --color-gray: #646972;
    --color-black: #000;

    --color-brand: #3d63dd;

    --highlight-red: #fae3e1;
    --highlight-purple: #D7DAF4;
    --highlight-blue: #e4e9ff;
    --highlight-green: #dcf1e2;
    --highlight-yellow: #FCF1D5;
    --highlight-orange: #FBE6D4;
    --highlight-pink: #fce5ef;
    --highlight-gray: #E2E3E5;

    // dark mode
    --dark-color-red: #E05353;
    --dark-color-purple: #5F6AD3;
    --dark-color-blue: #7eb5ff;
    --dark-color-green: #4CB783;
    --dark-color-yellow: #F2BE00;
    --dark-color-orange: #F19949;
    --dark-color-pink: #ff92ad;
    --dark-color-gray: #8A8F98;
    --dark-color-black: #050505;

    --dark-color-brand: #3d63dd;

    --dark-highlight-red: #251A1A;
    --dark-highlight-purple: #282C48;
    --dark-highlight-blue: #384575;
    --dark-highlight-green: #1E342B;
    --dark-highlight-yellow: #4C4328;
    --dark-highlight-orange: #4C3828;
    --dark-highlight-pink: #693d54;
    --dark-highlight-gray: #323539;

    // revision
    --revision-color-red: #e04f44;
    --revision-color-purple: #5f55ee;
    --revision-color-blue: #4c68d6;
    --revision-color-green: #259646;
    --revision-color-yellow: #cf940a;
    --revision-color-orange: #bc5f1c;
    --revision-color-pink: #c75485;
    --revision-color-gray: #87909e;

    --dark-revision-color-red: #eb8e87;
    --dark-revision-color-purple: #9992f4;
    --dark-revision-color-blue: #7db4fc;
    --dark-revision-color-green: #73cd8c;
    --dark-revision-color-yellow: #fbcb5c;
    --dark-revision-color-orange: #eca06a;
    --dark-revision-color-pink: #ff92ad;
    --dark-revision-color-gray: #adb3bd;
    --dark-revision-color-black: #000;
  }

  .container {
    @apply mx-auto;
  }

  html {
    --yellow: var(--color-yellow);
    --green: var(--color-green);
    --red: var(--color-red);
    --purple: var(--color-purple);
    --blue: var(--color-blue);
    --orange: var(--color-orange);
    --pink: var(--color-pink);
    --gray: var(--color-gray);
    --black: var(--color-black);
    --brand: var(--color-brand);
    --default: var(--color-gray);

    //revision
    --revision-red: var(--revision-color-red);
    --revision-purple: var(--revision-color-purple);
    --revision-blue: var(--revision-color-blue);
    --revision-green: var(--revision-color-green);
    --revision-yellow: var(--revision-color-yellow);
    --revision-orange: var(--revision-color-orange);
    --revision-pink: var(--revision-color-pink);
    --revision-gray: var(--revision-color-gray);

    // highlights
    --hl-red: var(--highlight-red);
    --hl-purple: var(--highlight-purple);
    --hl-blue: var(--highlight-blue);
    --hl-green: var(--highlight-green);
    --hl-yellow: var(--highlight-yellow);
    --hl-orange: var(--highlight-orange);
    --hl-pink: var(--highlight-pink);
    --hl-gray: var(--highlight-gray);
  }

  html[data-theme="dark"] {
    input {
      caret-color: #adb3bd;
    };
    color-scheme: "dark";
    --yellow: var(--dark-color-yellow);
    --green: var(--dark-color-green);
    --red: var(--dark-color-red);
    --purple: var(--dark-color-purple);
    --blue: var(--dark-color-blue);
    --orange: var(--dark-color-orange);
    --pink: var(--dark-color-pink);
    --gray: var(--dark-color-gray);
    --black: var(--dark-color-black);
    --default: var(--dark-color-gray);

    // highlights
    --hl-red: var(--dark-highlight-red);
    --hl-purple: var(--dark-highlight-purple);
    --hl-blue: var(--dark-highlight-blue);
    --hl-green: var(--dark-highlight-green);
    --hl-yellow: var(--dark-highlight-yellow);
    --hl-orange: var(--dark-highlight-orange);
    --hl-pink: var(--dark-highlight-pink);
    --hl-gray: var(--dark-highlight-gray);
    --hl-default: var(--dark-highlight-gray);

    //revision
    --revision-red: var(--dark-revision-color-red);
    --revision-purple: var(--dark-revision-color-purple);
    --revision-blue: var(--dark-revision-color-blue);
    --revision-green: var(--dark-revision-color-green);
    --revision-yellow: var(--dark-revision-color-yellow);
    --revision-orange: var(--dark-revision-color-orange);
    --revision-pink: var(--dark-revision-color-pink);
    --revision-gray: var(--dark-revision-color-gray);
    --revision-black: var(--dark-revision-color-black);

    ::-moz-selection {
      /* Code for Firefox */
      @apply bg-backgroundColors-foreground-light/40 dark:bg-backgroundColors-foreground-dark/50;
    }

    ::selection {
      @apply bg-backgroundColors-foreground-light/40 dark:bg-backgroundColors-foreground-dark/50;
    }
  }

  .revision-mark[datatooltip="revision"]:before {
    background: #FF3400;
    cursor: e-resize;
  }

  // match all selectors with dataTooltip property
  [datatooltip]::before {
    position: relative;
    cursor: help;
  }

  .tippy-popper[x-out-of-boundaries] {
    display: none;
  }
  .tippy-tooltip[data-out-of-boundaries] {
    display: none;
  }

  [type="checkbox"]:checked {
    @apply bg-backgroundColors-foreground-light dark:bg-backgroundColors-foreground-dark focus:ring-1 focus:ring-offset-2 focus:ring-gray-500;
  }

  [type="checkbox"] {
    @apply bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark;
  }

  * {
    @apply text-textColors-background-light dark:text-textColors-background-dark;
    @apply antialiased;
    font-family: Inter,  sans-serif;
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
    @apply text-textColors-muted-light dark:text-textColors-muted-dark;
  }

  .ProseMirror p.is-empty::before {
    @apply text-textColors-muted-light dark:text-textColors-muted-dark;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .ProseMirror:not(.dragging) .ProseMirror-selectednode:is(.node-reflect) {
    div {
      div {
       div { @apply w-[1.2rem] bg-reflect-light dark:bg-reflect-dark text-inherit }
      }
    }
  }

  .ProseMirror:not(.dragging) .ProseMirror-selectednode:not(.node-reflect) {
    outline: none !important;
    border-radius: 0.2rem;
    transition: background-color 0.2s;
    box-shadow: none;
  }

  .drag-handle {
    @apply -mt-[3px] text-lg text-textColors-muted-light dark:text-textColors-muted-dark;
    display: none;
  }
}

// prose
.bullet-list > li {
  margin-left: 1rem;
}

.prose :where(ul > li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose
  :where(blockquote p:first-of-type):not(
    :where([class~="not-prose"], [class~="not-prose"] *)
  )::before {
  content: "“";
}

.prose
  :where(blockquote p:last-of-type):not(
    :where([class~="not-prose"], [class~="not-prose"]*)
  )::after {
  content: "”";
}

// Masonry
.cheatsheets {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

div[data-radix-popper-content-wrapper] {
  z-index: 1500; // override chakra ui modal styles
}

.editor-link {
  font-size: inherit;
}

.ProseMirror {
  strong {
    font-weight: 500;
  }

  .custom-tasklist > div > p {
    margin-bottom: 0;
  }

  @for $i from 0 through 8 {
    p[data-indent="#{$i}"] {
      padding-left: $i * 1em;
    }
  }

  :first-child {
    margin-top: 0;
  }

  .editor-task-list {
    list-style-type: none;
  }

  .editor-task-list > li {
    display: flex;
    align-items: flex-start;
  }

  ul[data-type="taskList"] li > label {
    margin-right: 0.2rem;
  }

  ul[data-type="taskList"] li[data-checked="true"] > div > p {
    @apply text-textColors-muted-light dark:text-textColors-muted-dark transition-colors;
  }

  ul[data-type="taskList"] li > label input[type="checkbox"] {
    appearance: none;
    margin: 0;
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    position: relative;
    top: 3px;
    margin-right: 0.3rem;
    display: grid;
    place-content: center;
    border-radius: 4px;
  }

  // .editor-task-item {
  //   display: flex;
  //   margin-left: -8px;
  //   column-gap: 8px;
  //   align-items: flex-start;
  // }

  // .editor-task-item > div > p,
  // ul > li > p {
  //   margin: 0;
  //   margin-top: -2px;
  // }

  pre.dark {
    border-radius: 0.2rem;

    .hljs-comment,
    .hljs-quote {
      color: #999999;
      font-family: var(--font-code);
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #e0e0e0;
      font-family: var(--font-code);
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #a5d6ff;
      font-family: var(--font-code);
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #a5d6ff;
      font-family: var(--font-code);
    }

    .hljs-title,
    .hljs-section {
      color: #d1a8fe;
      font-family: var(--font-code);
    }

    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-attr {
      color: #fe7a72;
      font-family: var(--font-code);
    }

    .hljs-emphasis {
      font-style: italic;
      font-family: var(--font-code);
    }

    .hljs-strong {
      font-weight: 700;
      font-family: var(--font-code);
    }
  }

  pre.light {
    border-radius: 0.2rem;

    .hljs-comment,
    .hljs-quote {
      color: #6a737d;
      font-family: var(--font-code);
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #24292e;
      font-family: var(--font-code);
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #1161c7;
      font-family: var(--font-code);
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #0a3069;
      font-family: var(--font-code);
    }

    .hljs-title,
    .hljs-section {
      color: #6f42c1;
      font-family: var(--font-code);
    }

    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-attr {
      color: #d73949;
      font-family: var(--font-code);
    }

    .hljs-emphasis {
      font-style: italic;
      font-family: var(--font-code);
    }

    .hljs-strong {
      font-weight: 700;
      font-family: var(--font-code);
    }
  }
}

// truncate
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popover-content {
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.overlay {
  background-color: rgba(0, 0, 0, 0.447);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog-content {
  border-radius: 6px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.base-text {
  // font-size: 0.9375rem;
  line-height: 1.5rem;
}

// cheatsheets
.cheatsheet
  > div.tiptap-editor
  > div
  > div.ProseMirror
  > p > code {
  @apply bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark;
  @apply border border-solid border-border-primary-light/50 z-10 dark:border-border-primary-dark;
}

.cheatsheet
  > div.tiptap-editor
  > div
  > div.ProseMirror
  > .node-codeBlock
  > div.code-block
  > div.editor-code-block {
  padding: 0;
  background-color: transparent;
}

.cheatsheet > div.tiptap-editor > div > div.ProseMirror > hr {
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 1em;
  margin-top: 1em;
}

.cheatsheet > article > div > div > div.ProseMirror > hr {
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 1em;
  margin-top: 1em;
}

.cheatsheet
  > div.tiptap-editor
  > div
  > div.ProseMirror
  > .node-codeBlock
  > div.code-block
  > div.language-picker,
.cheatsheet
  > div.tiptap-editor
  > div
  > div.ProseMirror
  > .node-codeBlock
  > div.code-block
  > div.editor-code-block
  > div {
  display: none;
}

.cheatsheet
  > div.tiptap-editor
  > div
  > div.ProseMirror
  > .node-codeBlock
  > div.code-block
  > div.editor-code-block
  > pre
  > code {
  display: block;
}

.cheatsheet
  > div.tiptap-editor
  > div
  > div.ProseMirror
  > .node-codeBlock
  > div.code-block
  > div.editor-code-block
  > pre
  > code
  > div,
code > div,
code > * {
  font-family:
    "SFMono Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace // The final fallback for rendering in monospace.
 !important;
  font-size: 0.95em;
  line-height: 1.6em;
}
